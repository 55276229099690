import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { route } from "app/config/routes/route";
import DropdownUserNavigation from "templates/includes/I_DropdownUserNavigation";
import useAuth from "app/hooks/useAuth";
import SymbolImage from "components/SymbolImage";

export const name = (name, surname, username) => {
    if(name) return `${name??''} ${surname??''}`;
    if(username && username.indexOf('@')<username.indexOf('.') && username.indexOf('.')>0) {
        return username.substr(0, username.indexOf('@'));
    }
    return username;
}

const QuickUserToggler = () => {
    const { user } = useSelector(({ auth }) => auth);
    const { isLoggedIn } = useAuth();

    return (<>
        <div className="topbar-item">
            {isLoggedIn() && <>
                <DropdownUserNavigation>
                    <div className="w-auto d-flex align-items-center px-2 btn btn-clean">
                        <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                            {name(user?.data?.name, null, user?.data?.username)}
                        </span>
                        <span className="symbol symbol-35">
                            <span className="font-size-h5 font-weight-bold">
                                {user.data.profilePhotoUrl && <SymbolImage maxWidth={35} className="rounded" src={user.data.profilePhotoUrl} alt=""/>}
                            </span>
                        </span>
                    </div>
                </DropdownUserNavigation>
            </>}
            {!isLoggedIn() && <>
                <Link to={route['auth.login']} className="btn btn-icon w-auto btn-light d-flex align-items-center px-5 mx-1">
                    Zaloguj
                </Link>
                <Link to={route['auth.register']} className="btn btn-icon w-auto btn-primary d-flex align-items-center px-5 mx-1">
                    Zarejestruj
                </Link>
            </>}
        </div>
    </>);
}

export default QuickUserToggler;
